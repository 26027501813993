
import { Vue, Options } from 'vue-class-component';
import {
  SearchInput,
  BaseButton,
  GroupTreeList,
  BaseModal,
  BaseSelect,
  BaseTextInput,
  BasePagination,
  ActionModal,
  BaseIcon
} from '@/lib/components';
import { Domain, Organisation } from '@/models';
import { DomainService, OrganisationService } from '@/services/api';
import { IGroupSegment, IModalAction, IOption } from '@/lib';
import { DomainOrganisationService } from '@/services/api/domain-organisation.service';
import { searchTree, filterTree, getParentTree } from '@/helpers/organisations.helper';
import { firstLetterToUpper } from '@/lib/helpers/string.helper';
import axios, { CancelTokenSource } from 'axios';
import { FEATURES, ORGANISATION_NAME_MAX_LENGTH, ORGANISATION_DESCRIPTION_MAX_LENGTH } from '@/constants';
import { isFeatureFlagEnabledOnDomain } from '@/helpers/feature-flag.helper';
import { useProgressStore } from '@/stores/progress.store';
import { useNotificationStore } from '@/stores/notification.store';

@Options({
  components: {
    ActionModal,
    BasePagination,
    BaseSelect,
    BaseTextInput,
    BaseModal,
    GroupTreeList,
    BaseButton,
    SearchInput,
    BaseIcon
  }
})
export default class OrganisationsPage extends Vue {
  search = '';
  progressStore = useProgressStore();
  notificationStore = useNotificationStore();

  types: Array<string> = ['organisations', 'domain', 'subdomain'];
  orgService = new OrganisationService();
  domainService = new DomainService();

  showNewOrganisationModal = false;
  name = '';
  type = '';
  description = '';
  parent: Organisation | Domain | null = null;

  errors: { [key: string]: Array<string> } = {};
  organisation: Organisation | Domain | null = null;
  domains: Domain[] = [];

  request: CancelTokenSource = axios.CancelToken.source();

  get shouldLimitNameLength(): boolean {
    return this.type === 'organisations' && isFeatureFlagEnabledOnDomain(FEATURES.ORGANISATION_NAME_LIMIT, this.parent as Domain);
  }

  get nameLimit(): number | null {
    return this.shouldLimitNameLength ? ORGANISATION_NAME_MAX_LENGTH : null;
  }

  get parentTree() {
    const parentTree = this.parent
      ? this.domains
        .map((domain) => getParentTree((this.parent as Domain).id, domain).map((organisation) => organisation.name))
        .find((arr) => arr.length)
      : null;
    if (parentTree && this.parent) {
      return parentTree.concat(this.parent.name);
    } else if (this.parent) {
      return [this.parent.name];
    }
    return null;
  }

  get typeOptions(): Array<IOption> {
    return this.types
      .filter((type) => (this.parent ? type !== 'domain' : type === 'domain'))
      .map((type) => ({
        label: `${firstLetterToUpper(type)}`,
        value: type
      }));
  }

  get filteredElements(): Array<Organisation> {
    return filterTree(this.domains, this.search);
  }

  get filteredOrgGroup(): Array<IGroupSegment> {
    return this.filteredElements.map(this.organisationToGroup);
  }

  async created() {
    await this.fetchDomains();
  }

  beforeUnmount() {
    if (this.request) {
      this.request.cancel();
    }
  }

  async fetchDomains() {
    try {
      this.progressStore.startProgress();
      this.domains = (
        await this.domainService.index({
          cancelToken: this.request.token
        })
      ).data;
      this.progressStore.finishProgress();
    } catch (e) {
      if (!axios.isCancel(e)) {
        await this.notificationStore.addErrorNotification({
          title: this.$t('platform.org-unit.fetch-error')
        });
        this.progressStore.setError();
      }
    }
  }

  getActivationModalActions(organisation: Organisation): Array<IModalAction> {
    return [
      {
        label: this.$t('platform.common.confirm-submit') as string,
        color: 'danger',
        onClick: () => this.setActivationStatus(organisation.id, !organisation.is_active)
      },
      {
        label: this.$t('platform.common.cancel') as string,
        color: 'ghost',
        onClick: () => (this.organisation = null)
      }
    ];
  }

  async setActivationStatus(id: string, is_active: boolean) {
    try {
      is_active ? await this.orgService.activate(id) : await this.orgService.deactivate(id);
      this.notificationStore.addSuccessNotification({
        title: this.$t('platform.common.success'),
        label: this.$t('platform.org-unit.update-status-success')
      });
      await this.fetchDomains();
    } catch (e) {
      this.notificationStore.addErrorNotification({
        title: this.$t('platform.common.failure'),
        label: this.$t('platform.org-unit.update-status-error')
      });
    }
  }

  organisationToGroup(org: Organisation | Domain): IGroupSegment {
    return {
      id: org.id,
      name: org.name || '',
      description: org.description || '',
      isActive: typeof (org as Organisation).is_active === 'undefined' ? true : !!(org as Organisation).is_active,
      add: org.type === 'domain' || org.type === 'subdomain',
      ...(org.type
        ? {
          label: {
            name: this.$t(`platform.domain-admin.${org.type}.name`) as string,
            color: org.type === 'organisations' ? 'primary' : 'success'
          }
        }
        : {}),
      actions: [
        {
          label: (org.type
            ? this.$t(`platform.domain-admin.${org.type}.edit`)
            : this.$t('platform.common.edit')) as string,
          onClick: () => {
            this.$router.push({
              name: 'domain-admin-organisation',
              params: { organisationId: org.id }
            });
          }
        }
      ],
      children: this.getChildren(org as Domain)
    };
  }

  getChildren(org: Domain): Array<IGroupSegment> {
    let children: Array<IGroupSegment> = [];
    if (org.subdomains && org.subdomains.length) {
      children = [...children, ...org.subdomains.map((child) => this.organisationToGroup(child))];
    }
    if (org.organisations && org.organisations.length) {
      children = [...children, ...org.organisations.map((child) => this.organisationToGroup(child))];
    }
    return children;
  }

  getOrganisation(id: string): Organisation | Domain | null | undefined {
    return this.domains.map((org) => searchTree(org, id)).find((org) => org);
  }

  createChild(id: string) {
    this.showNewOrganisationModal = true;
    this.parent = this.getOrganisation(id) ?? null;
  }

  validateDescription() {
    if (this.description.length > ORGANISATION_DESCRIPTION_MAX_LENGTH) {
      this.errors = {
        ...this.errors,
        description: [this.$t('platform.domain-admin.description-error', [ORGANISATION_DESCRIPTION_MAX_LENGTH]) as string]
      };
    } else if (this.errors.description && this.errors.description.length) {
      this.errors = {
        ...this.errors,
        description: []
      };
    }
  }

  cancelOrganisation() {
    this.showNewOrganisationModal = false;
    this.parent = null;
    this.name = '';
    this.description = '';
    this.type = '';
  }

  validateName() {
    this.errors = {
      ...this.errors,
      name: (this.shouldLimitNameLength && this.name.length > ORGANISATION_NAME_MAX_LENGTH) ? [this.$t('platform.domain-admin.organisations.errors.name', [ORGANISATION_NAME_MAX_LENGTH]) as string] : []
    };
  }

  async saveOrganisation() {
    try {
      const organisation = {
        name: this.name,
        description: this.description,
        parent_id: this.parent ? this.parent.id : null
      };
      let response: Domain | Organisation;
      if (this.type === 'organisations' && this.parent) {
        response = (await new DomainOrganisationService(this.parent.id).create(organisation)).data;
      } else {
        response = (await this.domainService.create(organisation)).data;
      }
      await this.fetchDomains();
      this.cancelOrganisation();
      this.notificationStore.addSuccessNotification({
        title: response.name || response.type,
        label: this.$t('platform.org-unit.create-success')
      });
    } catch (e) {
      if (e.response.status === 422) {
        this.errors = e.response.data.errors;
      } else {
        this.notificationStore.addErrorNotification({
          title: this.$t('platform.org-unit.create-error')
        });
      }
    }
  }
}
