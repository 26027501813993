import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "my-12" }
const _hoisted_2 = { class: "container" }
const _hoisted_3 = { class: "flex justify-between -mt-4 mb-6 border-b" }
const _hoisted_4 = { class: "flex items-center text-lg font-bold text-gray-900" }
const _hoisted_5 = { class: "flex py-4" }
const _hoisted_6 = {
  class: "w-full max-w-lg p-10 space-y-8",
  style: {"width":"100vh"}
}
const _hoisted_7 = { class: "text-xl font-semibold text-gray-900" }
const _hoisted_8 = { class: "space-y-6" }
const _hoisted_9 = {
  key: 0,
  style: {"width":"max-content"},
  class: "border rounded-md text-sm font-medium space-x-4 px-4"
}
const _hoisted_10 = { class: "grid grid-cols-1 gap-x-6 gap-y-8" }
const _hoisted_11 = { class: "flex flex-row items-center justify-end space-x-2" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SearchInput = _resolveComponent("SearchInput")!
  const _component_GroupTreeList = _resolveComponent("GroupTreeList")!
  const _component_BaseIcon = _resolveComponent("BaseIcon")!
  const _component_BaseTextInput = _resolveComponent("BaseTextInput")!
  const _component_BaseSelect = _resolveComponent("BaseSelect")!
  const _component_BaseButton = _resolveComponent("BaseButton")!
  const _component_BaseModal = _resolveComponent("BaseModal")!
  const _component_ActionModal = _resolveComponent("ActionModal")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("section", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("div", _hoisted_4, _toDisplayString(_ctx.search.length ? _ctx.$t('platform.common.search-results') : _ctx.$t('platform.org-unit.all')), 1),
          _createElementVNode("div", _hoisted_5, [
            _createVNode(_component_SearchInput, {
              modelValue: _ctx.search,
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.search) = $event)),
              class: "text-sm",
              icon: "search"
            }, null, 8, ["modelValue"])
          ])
        ]),
        _createVNode(_component_GroupTreeList, {
          items: _ctx.filteredOrgGroup,
          onCreateChild: _cache[1] || (_cache[1] = ($event: any) => (_ctx.createChild($event)))
        }, null, 8, ["items"])
      ])
    ]),
    (_ctx.showNewOrganisationModal)
      ? (_openBlock(), _createBlock(_component_BaseModal, {
          key: 0,
          class: "inline-block bg-white",
          onClose: _ctx.cancelOrganisation
        }, {
          default: _withCtx(() => [
            _createElementVNode("div", _hoisted_6, [
              _createElementVNode("h1", _hoisted_7, _toDisplayString(_ctx.$t('platform.org-unit.new')), 1),
              _createElementVNode("div", _hoisted_8, [
                (_ctx.parentTree && _ctx.parentTree.length)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_9, [
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.parentTree, (p, index) => {
                        return (_openBlock(), _createElementBlock("div", {
                          key: p,
                          class: "inline-flex space-x-4 items-center min-h-8"
                        }, [
                          _createElementVNode("span", null, _toDisplayString(p), 1),
                          (index < _ctx.parentTree.length - 1)
                            ? (_openBlock(), _createBlock(_component_BaseIcon, {
                                key: 0,
                                name: "right-vector-arrow",
                                height: "32px"
                              }))
                            : _createCommentVNode("", true)
                        ]))
                      }), 128))
                    ]))
                  : _createCommentVNode("", true),
                _createElementVNode("div", _hoisted_10, [
                  _createVNode(_component_BaseTextInput, {
                    modelValue: _ctx.name,
                    "onUpdate:modelValue": [
                      _cache[2] || (_cache[2] = ($event: any) => ((_ctx.name) = $event)),
                      _ctx.validateName
                    ],
                    label: _ctx.$t('platform.common.name'),
                    placeholder: _ctx.$t('platform.org-unit.name'),
                    error: _ctx.errors.name && _ctx.errors.name.length ? _ctx.errors.name[0] : null,
                    count: _ctx.nameLimit,
                    "data-dusk": "org-name"
                  }, null, 8, ["modelValue", "label", "placeholder", "error", "count", "onUpdate:modelValue"]),
                  _createVNode(_component_BaseSelect, {
                    modelValue: _ctx.type,
                    "onUpdate:modelValue": [
                      _cache[3] || (_cache[3] = ($event: any) => ((_ctx.type) = $event)),
                      _ctx.validateName
                    ],
                    label: _ctx.$t('platform.org-unit.category'),
                    placeholder: _ctx.$t('platform.org-unit.select-category'),
                    options: _ctx.typeOptions,
                    "data-dusk": "org-type"
                  }, null, 8, ["modelValue", "label", "placeholder", "options", "onUpdate:modelValue"]),
                  _createVNode(_component_BaseTextInput, {
                    modelValue: _ctx.description,
                    "onUpdate:modelValue": [
                      _cache[4] || (_cache[4] = ($event: any) => ((_ctx.description) = $event)),
                      _ctx.validateDescription
                    ],
                    "is-optional": "",
                    count: 30,
                    label: _ctx.$t('platform.common.description'),
                    error: _ctx.errors.description && _ctx.errors.description.length ? _ctx.errors.description[0] : null,
                    placeholder: _ctx.$t('platform.org-unit.add-description')
                  }, null, 8, ["modelValue", "label", "error", "placeholder", "onUpdate:modelValue"])
                ])
              ]),
              _createElementVNode("div", _hoisted_11, [
                _createVNode(_component_BaseButton, {
                  color: "ghost",
                  "data-dusk": "org-cancel-btn",
                  onClick: _ctx.cancelOrganisation
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.$t('platform.common.cancel')), 1)
                  ]),
                  _: 1
                }, 8, ["onClick"]),
                _createVNode(_component_BaseButton, {
                  "data-dusk": "org-save-btn",
                  onClick: _ctx.saveOrganisation
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.$t('platform.org-unit.create')), 1)
                  ]),
                  _: 1
                }, 8, ["onClick"])
              ])
            ])
          ]),
          _: 1
        }, 8, ["onClose"]))
      : _createCommentVNode("", true),
    (_ctx.organisation && _ctx.organisation.type === 'organisations')
      ? (_openBlock(), _createBlock(_component_ActionModal, {
          key: 1,
          icon: "warning",
          "icon-color": "alert-400",
          title: 
        _ctx.$t(
          _ctx.organisation.is_active
            ? `platform.org-unit.deactivate-${_ctx.organisation.type}`
            : `platform.org-unit.activate-${_ctx.organisation.type}`
        )
      ,
          body: _ctx.organisation.is_active ? _ctx.$t('platform.org-unit.deactivate-message') : '',
          actions: _ctx.getActivationModalActions(_ctx.organisation),
          onClose: _cache[5] || (_cache[5] = ($event: any) => (_ctx.organisation = null))
        }, null, 8, ["title", "body", "actions"]))
      : _createCommentVNode("", true)
  ]))
}