import { Domain, Organisation } from '@/models';

export const searchTree = (element: Organisation | Domain, id: string): Organisation | Domain | null => {
  const subdomains = (element as Domain).subdomains;
  const organisations = (element as Domain).organisations;

  if (element.id === id) {
    return element;
  }

  let result: Organisation | Domain | null = null;
  if (subdomains && subdomains.length) {
    for (let i = 0; !result && i < subdomains.length; i++) {
      result = searchTree(subdomains[i], id);
    }
  }
  if (organisations && organisations.length) {
    for (let i = 0; !result && i < organisations.length; i++) {
      result = searchTree(organisations[i], id);
    }
  }
  return result;
};

export const filterTree = (element: Array<Organisation | Domain>, value: string) => {
  const res = JSON.parse(JSON.stringify(element));
  return res.filter((org: Organisation | Domain) => {
    const subdomains = (org as Domain).subdomains;
    const organisations = (org as Domain).organisations;
    if (subdomains && subdomains.length) {
      (org as Domain).subdomains = filterTree(subdomains, value);
    }
    if (organisations && organisations.length) {
      (org as Domain).organisations = filterTree(organisations, value);
    }
    const name = org.name || '';
    return (
      name.toLowerCase().includes(value.toLowerCase()) ||
      (subdomains && subdomains.length) ||
      (organisations && organisations.length)
    );
  });
};

export const getParentTree = (id: string, tree: Organisation | Domain): Array<Organisation | Domain> => {
  const loop = (path: Array<Organisation | Domain>, organisation: Domain | Organisation) => {
    if (organisation.id === id) {
      return path;
    }
    const subdomains = (organisation as Domain).subdomains;
    const organisations = (organisation as Domain).organisations;
    let res: Array<Organisation | Domain> = [];
    if (subdomains && subdomains.length) {
      res = res.concat(
        subdomains.reduce(
          (acc, child) => acc.concat(loop([...path, organisation], child)),
          [] as Array<Organisation | Domain>
        )
      );
    }
    if (organisations && organisations.length) {
      res = res.concat(
        organisations.reduce(
          (acc, child) => acc.concat(loop([...path, organisation], child)),
          [] as Array<Organisation | Domain>
        )
      );
    }
    return res;
  };
  return loop([], tree);
};
